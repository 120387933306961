
import { Component, Vue } from "vue-property-decorator"

import { FLSelect } from "@/components/base/FLSelect.vue"
import { SET_PAGE_TITLE } from "@/store/store-types"
import { langOptions, messages, TSupportedLocales } from "src/i18n"
import { LocaleMessageObject } from "vue-i18n"

@Component({
  components: { FLSelect },
})
export class TranslationsView extends Vue {
  base: TSupportedLocales = "en"
  compare: TSupportedLocales = "de"
  copyButtonText = "Copy"

  get options() {
    return langOptions
  }
  mounted() {
    this.$store.commit(SET_PAGE_TITLE, {
      title: "Translations",
      translate: false,
    })
  }

  missingKeys(base: LocaleMessageObject, translation: LocaleMessageObject) {
    const missing = Object.entries(base)
      .filter(([k, v]) => !!k && !translation[k])
      .map((tuple) => [
        tuple[0],
        // Coercing to a string here might break, but I can't be bothered with
        // figuring out a correct way to handle this in TS:
        String(tuple[1])
          .trim()
          .replace(/\s\s+/g, " "),
      ])
    return missing
  }

  missingToObj(missing: string[][]) {
    return Object.fromEntries(missing)
  }

  getObj(key: TSupportedLocales) {
    const obj = messages[key]
    if (!obj) {
      throw TypeError(`Invalid translations key: ${key}`)
    }
    return obj
  }

  async copyMissing() {
    function sleep(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms))
    }
    navigator.clipboard.writeText(this.missingText).then(
      /* clipboard successfully set */
      () => {
        this.copyButtonText = "Copied ☑"
      },
      /* clipboard write failed */
      () => {
        this.copyButtonText = "Copy failed :("
      }
    )
    await sleep(5000)
    this.copyButtonText = "Copy"
  }

  get missing() {
    const baseObj = this.getObj(this.base)
    const compareObj = this.getObj(this.compare)
    return this.missingKeys(baseObj, compareObj)
  }

  get keysMissing() {
    return this.missing.length
  }

  get missingText() {
    // return this.missingToObj(this.missing)
    return JSON.stringify(this.missingToObj(this.missing), null, 2)
  }
}

export default TranslationsView
